@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
* {
  /* font-family: 'Noto Sans JP' !important; */
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
}
.leaflet-container {
  width: 100vw;
  height: 100vh;
}

:root {
  --color-primary: #0d3956;
}
