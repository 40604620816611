@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
a,
a:hover {
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.footer1 {
  background-color: var(--color-primary);
  margin-top: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif !important;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-label {
  color: white;
  margin-top: 13px;
  font-size: 15px;
}

.form-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  margin: 5px;
  padding: 5px;
}

.email {
  width: 650px;
  height: 45px;
  border-radius: 7px;
  padding: 0 15px;
  font-size: 15px;
  outline: none;
}

.email::placeholder {
  color: rgb(136, 136, 136) !important;
}

.form-btn {
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 16px;
  color: var(--color-primary);
  outline: none;
  border: none;
  transition-duration: 0.3s;
  cursor: pointer;
}

.form-btn:hover {
  color: white;
  background-color: transparent;
}

.subscribe-msg {
  color: white;
  text-align: center;
  font-size: 20px;
  padding: 2px 5px;
}

.footer-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.footer-nav {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  gap: 1rem;
  width: 100%;
}

.footer-cols {
  flex: 75%;
  display: flex;
  justify-content: space-evenly;
}

.footer-col {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 16px;
  gap: 1rem;
  padding: 10px;
}

.socials {
  border-left: 4px solid white;
  flex: 25%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: white;
  text-align: start;
  padding-left: 20px;
}

.socials-heading {
  font-size: 18px;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.email-contact {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact {
  display: flex;
  gap: .3rem;
}

.copyright {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
}

@media screen and (max-width: 700px) {
  .footer1 {
    gap: 0;
  }

  .form-input {
    padding: 0 20px;
  }

  .email {
    width: 100%;
  }

  .form-btn {
    width: 180px;
    padding: 8px 16px;
  }

  .footer-nav {
    flex-direction: column;
    padding: 0;
  }

  .footer-cols {
    flex-direction: column;
    gap: 1rem;
  }

  .footer-col {
    text-align: center;
  }

  .socials {
    border-left: none;
    text-align: center;
    margin-top: 20px;
    padding-left: 0;
    gap: 1rem;
  }

  .social-icons {
    justify-content: center;
  }

  .contact {
    justify-content: center;
  }
}
